<template>
  <AModalContent :show-close="true" class="send-meth">
    <div class="send-meth-title">Send Meth{{ onChain ? '' : ' From Meth Bank' }}</div>
    <form class="send-meth-form" method="#" @submit.prevent="onSubmit">
      <div class="send-meth-input">
        <label>Destination Wallet</label>
        <input v-model="wallet" class="app-input" name="wallet" />
      </div>
      <div class="send-meth-input">
        <label>Amount</label>
        <input v-model="amount" class="app-input" name="amount" />
        <div class="send-meth-input-tooltip">Meth Balance: <b>{{ methBalance }} METH</b></div>
      </div>

      <button class="send-meth-button" :class="{ disabled: isSending }" type="submit">
        <img v-if="isSending" class="loader" src="../../assets/img/spinner-clear.svg" width="20" /> Send
      </button>
    </form>
  </AModalContent>
</template>
<script>
import { sendMeth } from "@/functions/sendMeth"
import { sendMethBankMeth } from "@/functions/sendMethBankMeth"
import { formatNumberSpaces } from "@/helpers/format-number-spaces"
import { useMethBankStore } from "@/store/meth-bank"
import { useWeb3Store } from "@/store/web3"

import AModalContent from "../Modal/AModalContent.vue"
export default {
  props: {
    onChain: {
      type: Boolean,
    }
  },
  components: { AModalContent },

  data: () => ({
    amount: 0,
    wallet: '',
    isSending: false
  }),
  computed: {
    methBalance() {
      return formatNumberSpaces(useMethBankStore().balance)
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.isSending = true
        if (this.onChain) {
          await sendMeth(this.wallet, +this.amount)
        } else {
          await sendMethBankMeth(this.wallet, +this.amount)
        }
        useWeb3Store().update()
        this.$emit('close')
      } catch (err) {
        console.log(err)
      }
      this.isSending = false
    }
  },

}
</script>