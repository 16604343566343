import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import ENS from "ethereum-ens";
import { useWeb3Store } from "../store/web3";
import { useMethContract } from "../web3/contracts";

export const sendMeth = async function (wallet, amount) {
  const web3Store = useWeb3Store();
  // resolve ENS address
  if (!wallet.startsWith("0x") || wallet.endsWith(".eth")) {
    const { network } = Config;
    const ens = new ENS(network.rpcUrl.replace("sepolia", "mainnet"));
    try {
      wallet = await ens.resolver(wallet).addr();
    } catch (err) {
      console.log("Fail resolving ENS", err);
    }
  }
  const contract = useMethContract(true);

  const response = await useToastTransaction(
    contract.methods.transfer(wallet, +amount),
    {
      text: `Sending ${amount} METH `,
    }
  );
  useWeb3Store().update()
  return response
};
